import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";

import "./main.css";
import { store } from "./store";
import { iconsSet as icons } from "./assets/icons/icons.js";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";
import VueMoment from "vue-moment";
import vueNumeralFilterInstaller from "vue-numeral-filter";
const moment = require("moment");
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "babel-polyfill";

// import vueDebounce from "vue-debounce";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import "./plugins/icon";
import utils from "@/utils";
Vue.use(utils);
import "@/helper";
// Vue.use(helper);
import "@/services/axios";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import vSelect from "vue-select";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import i18next from "i18next";
import I18NextVue from "i18next-vue";
// console.log(i18next);
// Chart.register(ChartDataLabels);
Chart.plugins.register(ChartDataLabels);
function addCommas(nStr) {
  var x, x1, x2;

  nStr += "";
  x = nStr.split(".");
  x1 = x[0];
  x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}
Chart.defaults.global.plugins.datalabels.align = "top";
Chart.defaults.global.plugins.datalabels.anchor = "end";
Chart.defaults.global.plugins.datalabels.textStrokeWidth = 3;
Chart.defaults.global.plugins.datalabels.textStrokeColor = "rgb(255,255,255)";
// Chart.defaults.global.plugins.datalabels.backgroundColor = "rgb(255,255,255)";
// Chart.defaults.global.plugins.datalabels.clip = true;
Chart.defaults.scale.gridLines.color = "rgba(183,183,183,0.1)";
Chart.defaults.global.layout.padding = { top: 30, right: 30 };
// Chart.defaults.global.options.scales.yAxes[0].ticks.fontSize = 12;
// Chart.defaults.global.layout.margin = { top: 60, right: 30 };

Chart.defaults.global.plugins.datalabels.formatter = function (
  value,
  index,
  values
) {
  if (value > 0) {
    return addCommas(value);
  } else {
    return null;
  }
};

Vue.component("v-select", vSelect);

Vue.config.devtools = true;
Vue.config.performance = true;
Vue.use(CoreuiVue);
// Vue.use(VueFroala);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });
Vue.use(VueCookies, { expires: "7d" });
Vue.use(BootstrapVue);

Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use({
  install(Vue) {
    Vue.swal = swal;
    Vue.swal2 = swal2;
    Vue.prototype.$swal = swal;
    Vue.prototype.$swal2 = swal2;
  },
});
Vue.use(VueMoment, {
  moment,
});
Vue.use(I18NextVue, { i18next });
i18next.init({
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: false,
  resources: {},
});
Vue.component("datetime", Datetime);

Vue.prototype.$formatDateNew = "DD/MM/YYYY";
Vue.prototype.addCommas = (val) => addCommas(val);
Vue.prototype.$Chart = Chart;
Vue.prototype.$apiPath = process.env.VUE_APP_API;
Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatDateTime = "DD MMM YYYY (HH:mm)";
Vue.prototype.$formatTime = "HH:mm";
Vue.prototype.$bus = new Vue();
Vue.prototype.$TinyMceKey =
  process.env.VUE_APP_TINY_KEY ||
  "qz2bxoj52t4wq2tevakky8gwttlybve9ubbciwllh4meijwp";
Vue.prototype.convertTelePhone = (val) => {
  if (!val) return null;

  if (val.includes("-")) {
    let value = val.split("-");
    return `${value[0]} Ext. ${value[1]}`;
  }
  return `${val}`;
};
new Vue({
  el: "#app",

  router,
  store,
  // alert,
  // utility,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
